/* eslint-disable max-len */
import {
  Modal, Input, Button, Row, Col,
} from 'antd';
import { FC } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { IPaymentInfo } from '../../interface/Payment';

import './index.scss';

type IModalAddPayment = {
    visible: boolean
    handleOk: (info:IPaymentInfo) => void
    handleCancel: () => void
}

const ModalAddPayment:FC<IModalAddPayment> = ({ visible, handleOk, handleCancel }:IModalAddPayment) => {
  const {
    handleSubmit, control, reset,
  } = useForm<IPaymentInfo>();

  const handleCancelModal = () => {
    reset();
    handleCancel();
  };

  const handleOnSubmit = (data:IPaymentInfo) => {
    const returnValue = { ...data };
    handleOk(returnValue);
    setTimeout(() => {
      handleCancelModal();
    }, 300);
  };

  return (
    <>
      <Modal title={null} footer={false} closable={false} visible={visible} onCancel={handleCancelModal} bodyStyle={{ padding: '20px 47px' }}>
        <div className="modal__add__payment">
          <h3>
            Add payment infomation
          </h3>
          <form onSubmit={handleSubmit(handleOnSubmit)}>
            <Row gutter={[12, 12]} align="middle">
              <Col span={12}>
                Account Name
              </Col>
              <Col span={12}>
                <Controller
                  control={control}
                  name="accountName"
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <>
                      <Input className="input__order__confirm" onChange={onChange} value={value} />
                      {
                        error
                          ? (
                            <div className="error__input__text">
                              {error?.type === 'required' && 'This field is required.'}
                              {error?.type === 'minLength' && 'This field is least 4 char.'}
                              {error?.type === 'maxLength' && 'This field is max 100 char.'}
                            </div>
                          )
                          : null
                       }
                    </>
                  )}
                  rules={{
                    required: true, minLength: 4, maxLength: 100,
                  }}
                />
              </Col>
              <Col span={12}>
                Account Number
              </Col>
              <Col span={12}>
                <Controller
                  control={control}
                  name="accountNumber"
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <>
                      <Input className="input__order__confirm" onChange={onChange} value={value} />
                      {
                        error
                          ? (
                            <div className="error__input__text">
                              {error?.type === 'required' && 'This field is required.'}
                              {error?.type === 'pattern' && 'Please enter only number.'}
                              {error?.type === 'minLength' && 'This field is least 10 char.'}
                              {error?.type === 'maxLength' && 'This field is max 14 char.'}
                            </div>
                          )
                          : null
                       }
                    </>
                  )}
                  rules={{
                    required: true, pattern: /\d{10,14}/, minLength: 10, maxLength: 14,
                  }}
                />
              </Col>
              <Col span={12}>
                Branch
              </Col>
              <Col span={12}>
                <Controller
                  control={control}
                  name="branch"
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <>
                      <Input className="input__order__confirm" onChange={onChange} value={value} />
                      {
                        error
                          ? (
                            <div className="error__input__text">
                              {error?.type === 'required' && 'This field is required.'}
                              {error?.type === 'minLength' && 'This field is least 4 char.'}
                              {error?.type === 'maxLength' && 'This field is max 100 char.'}
                            </div>
                          )
                          : null
                       }
                    </>
                  )}
                  rules={{
                    required: true, minLength: 4, maxLength: 100,
                  }}
                />
              </Col>
              <Col span={12}>
                Brand Name
              </Col>
              <Col span={12}>
                <Controller
                  control={control}
                  name="brandName"
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <>
                      <Input className="input__order__confirm" onChange={onChange} value={value} />
                      {
                        error
                          ? (
                            <div className="error__input__text">
                              {error?.type === 'required' && 'This field is required.'}
                              {error?.type === 'minLength' && 'This field is least 4 char.'}
                              {error?.type === 'maxLength' && 'This field is max 100 char.'}
                            </div>
                          )
                          : null
                       }
                    </>
                  )}
                  rules={{
                    required: true, minLength: 4, maxLength: 100,
                  }}
                />
              </Col>
              <Col span={24}>
                <Row justify="space-around">
                  <Col>
                    <Button className="btn btn_close" onClick={handleCancelModal}>
                      Close
                    </Button>
                  </Col>
                  <Col>
                    <Button htmlType="submit" className="btn btn_confirm">
                      Confirm
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </form>
        </div>
      </Modal>
    </>
  );
};

export default ModalAddPayment;

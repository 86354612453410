/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  Col, Row, Space, Badge, Menu, Dropdown,
} from 'antd';

import { Link, useHistory } from 'react-router-dom';
import LogoIcon from '../../common/icons/logo_icon';
import NavAccountIcon from '../../common/icons/nav_account_icon';
import NavBellIcon from '../../common/icons/nav_bell_icon';

import { INavMenu, menu } from '../../common/nav_bar_menu';
import AuthHook from '../../hooks/auth-hook';
import './index.scss';

const TopNavigation = (): JSX.Element => {
  const router = useHistory();
  const path = router.location.pathname;

  const {
    logout, clearToken,
  } = AuthHook();

  const logoutClick = async () => {
    await logout();
    clearToken();
    router.push('/login');
  };

  const menuNav = (
    <Menu>
      <Menu.Item key="0">
        <Link to="/order">Orders</Link>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key="1">
        <Link to="/shipping">Shipping</Link>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key="4">
        <Link to="/shop">My Shop</Link>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key="5">
        <a aria-hidden onClick={() => logoutClick()}>Logout</a>
      </Menu.Item>
    </Menu>
  );

  return (
    <div className="nav__bar">
      <Row gutter={[40, 0]} align="middle" justify="center">
        <Col flex="155px">
          <LogoIcon />
        </Col>
        <Col flex="auto" />
        <Col>
          <div style={{ paddingTop: 10 }}>
            <Badge count={0}>
              <button type="button" className="button__icon">
                <NavBellIcon />
              </button>
            </Badge>
          </div>
        </Col>
        <Col>
          <Dropdown overlay={menuNav} trigger={['click']} placement="bottomRight" arrow overlayStyle={{ width: '180px', color: '#4A5463', fontFamily: 'Poppins' }}>
            <button type="button" className="button__icon" onClick={(e) => e.preventDefault()}>
              <NavAccountIcon />
            </button>
          </Dropdown>
        </Col>
        <Col span={24}>
          <Row wrap={false} justify="center">
            <Col>
              <Space size={6} wrap>
                {menu.map((m: INavMenu, index: number) => {
                  const key: React.Key = `menu_${index}`;
                  return (
                    <Link key={key} to={m.route} className={`nav_menu_link ${path.includes(m.route) ? 'active' : ''}`}>
                      {m.title}
                    </Link>
                  );
                })}
              </Space>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default TopNavigation;

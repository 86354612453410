/* eslint-disable max-len */
import { Button, Dropdown, Menu } from 'antd';
import { FC } from 'react';
import { useHistory } from 'react-router';
import { IOrderStatus, ITextOrderStatus } from '../interface/Order';

const OrderStatus:ITextOrderStatus[] = [
  {
    label: 'Wait for confirm order',
    orderStatus: '0',
    color: '#FF7332',
  },
  {
    label: 'Wait for payment',
    orderStatus: '1',
    color: '#F6C927',
  },
  {
    label: 'Wait for confirm payment',
    orderStatus: '2',
    color: '#1BD27E',
  },
  {
    label: 'Prepare Shipping',
    orderStatus: '3',
    color: '#F6C927',
  },
  {
    label: 'Shipped',
    orderStatus: '4',
    color: '#1BD27E',
  },
  {
    label: 'Recevied',
    orderStatus: '5',
    color: '#1BD27E',
  },
  {
    label: 'Complete',
    orderStatus: '6',
    color: '#1BD27E',
  },
  {
    label: 'Reject',
    orderStatus: '10',
    color: '#EE5252',
  },
];

type IOrderStatusProp = {
   orderId: string
   onShippingClick: (orderId:string) => void
} & IOrderStatus
const OrderStatusBadge: FC<IOrderStatusProp> = ({ orderStatus, orderId, onShippingClick } : IOrderStatusProp) => {
  const router = useHistory();
  const findStatus = OrderStatus.find((q) => q.orderStatus === orderStatus);
  const styled = {
    backgroundColor: findStatus?.color,
    color: 'white',
    fontSize: '12px',
    fontFamily: 'Poppins',
    border: 'none',
    borderRadius: '0px',
    borderColor: findStatus?.color,
    minWidth: '80px',
    width: '100%',
    maxWidth: '200px',
  };

  const toOrder = () => {
    if (orderStatus === '0') {
      router.push(`/order/confirm-order/${orderId}`);
    } else if (orderStatus === '2') {
      router.push(`/order/confirm-payment/${orderId}`);
    } else {
      console.log('orderStatus => ', orderStatus);
    }
  };

  const shippedOrder = () => {
    onShippingClick(orderId);
  };

  const menu = (
    <Menu>
      <Menu.Item key="1">
        Prepare shipping
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key="2" onClick={shippedOrder}>Shipped</Menu.Item>
    </Menu>
  );

  const buttonOrderStatus = () => {
    if (orderStatus === '3') {
      return (
        <Dropdown overlay={menu} trigger={['click']}>
          <Button size="small" style={styled}>
            <div style={{ paddingTop: 1 }}>
              {findStatus?.label}
            </div>
          </Button>
        </Dropdown>
      );
    }
    return (
      <Button onClick={toOrder} size="small" style={styled}>
        <div style={{ paddingTop: 1 }}>
          {findStatus?.label}
        </div>
      </Button>
    );
  };

  return buttonOrderStatus();
};

export default OrderStatusBadge;

/* eslint-disable no-useless-catch */
import { useAppDispatch, useAppSelector } from '../reducers/store';
import * as orderService from '../services/order-service';
import * as orderSlice from '../reducers/state/order-slice';
import { IOrderConfirmOrderRequest, IOrderRejectRequest } from '../interface/Order';

const orderHook = () => {
  const { orders, isLoading, total } = useAppSelector((state) => state.order);
  const dispatch = useAppDispatch();

  const fetchOrder = async (keyword = '', page = 1, size = 10) => {
    try {
      dispatch(orderSlice.setLoading(true));
      const { data } = await orderService.getAll(keyword, page, size);
      if (page <= 1) {
        dispatch(orderSlice.setOrder(data));
      } else {
        dispatch(orderSlice.addOrder(data));
      }
    } catch (err) {
      // throw err;
    } finally {
      dispatch(orderSlice.setLoading(false));
    }
  };

  const fetchOrderByStatus = async (orderStatus:string, keyword = '', page = 1, size = 10) => {
    try {
      dispatch(orderSlice.setLoading(true));
      const { data } = await orderService.getAllStatus(orderStatus, keyword, page, size);
      if (page <= 1) {
        dispatch(orderSlice.setOrder(data));
      } else {
        dispatch(orderSlice.addOrder(data));
      }
    } catch (err) {
      // throw err;
    } finally {
      dispatch(orderSlice.setLoading(false));
    }
  };

  const fetchOrderDetail = async (orderId :string) => {
    try {
      dispatch(orderSlice.setLoading(true));
      const { data } = await orderService.getOne(orderId);
      return data;
    } catch (err) {
      return undefined;
    } finally {
      dispatch(orderSlice.setLoading(false));
    }
  };

  const confirmOrder = async (formData:IOrderConfirmOrderRequest) => {
    dispatch(orderSlice.setLoading(true));
    await orderService.confirmOrder(formData);
    dispatch(orderSlice.setLoading(false));
  };

  const confirmPayment = async (orderId:string) => {
    dispatch(orderSlice.setLoading(true));
    await orderService.confirmPayment(orderId);
    dispatch(orderSlice.setLoading(false));
  };

  const shippingOrder = async (orderId:string) => {
    dispatch(orderSlice.setLoading(true));
    await orderService.shippingOrder(orderId);
    dispatch(orderSlice.setLoading(false));
  };

  const rejectOder = async (form:IOrderRejectRequest) => {
    dispatch(orderSlice.setLoading(true));
    await orderService.rejectOrder(form);
    dispatch(orderSlice.setLoading(false));
  };

  const fetchShipping = async (keyword = '', page = 1, size = 10) => {
    try {
      dispatch(orderSlice.setLoading(true));
      const { data } = await orderService.getAllShipping(keyword, page, size);
      if (page <= 1) {
        dispatch(orderSlice.setOrder(data));
      } else {
        dispatch(orderSlice.addOrder(data));
      }
    } catch (err) {
      // throw err;
    } finally {
      dispatch(orderSlice.setLoading(false));
    }
  };

  const fetchShippingByStatus = async (orderStatus:string, keyword = '', page = 1, size = 10) => {
    try {
      dispatch(orderSlice.setLoading(true));
      const { data } = await orderService.getAllShippingStatus(orderStatus, keyword, page, size);
      if (page <= 1) {
        dispatch(orderSlice.setOrder(data));
      } else {
        dispatch(orderSlice.addOrder(data));
      }
    } catch (err) {
      // throw err;
    } finally {
      dispatch(orderSlice.setLoading(false));
    }
  };

  return {
    fetchOrder,
    fetchOrderByStatus,
    fetchOrderDetail,
    rejectOder,
    confirmOrder,
    confirmPayment,
    shippingOrder,
    fetchShipping,
    fetchShippingByStatus,
    orders,
    isLoading,
    total,
  };
};

export default orderHook;

/* eslint-disable max-len */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/destructuring-assignment */
import OrderStatusBadge from '../order_status';
import { IColumnTableOrderProp, IOrder } from '../../interface/Order';
import { formatDateTime } from '../../common/hepler';

type IColumn = {
  onShippingClick: (orderId:string) => void
}
const ColumnTableOrder = ({ onShippingClick }:IColumn):IColumnTableOrderProp[] => [
  {
    title: 'Order ID',
    index: '_id',
  },
  {
    title: 'Create Date/Time',
    index: 'createdAt',
    render: (props: IOrder) => (
      <div>
        {formatDateTime(props.createdAt)}
      </div>
    ),
  },
  {
    title: 'Customer',
    index: 'retailerStoreName',
  },
  {
    title: 'Status',
    index: 'orderStatus',
    render: (props: IOrder) => <OrderStatusBadge orderId={props._id} orderStatus={props.orderStatus} onShippingClick={onShippingClick} />,
    align: 'center',
    width: '210px',
  },
  {
    title: 'Total',
    index: 'totalPrice',
    align: 'center',
    render: (props: IOrder) => (
      <div style={{ fontWeight: 300 }}>
        $
        {props.totalPrice}
      </div>
    ),
  },
];

export default ColumnTableOrder;

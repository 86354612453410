/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
import { FC } from 'react';
import ColumnTableOrder from './column';
import './index.scss';
import { IOrderProduct } from '../../interface/Order';

type ITableOrderProductProps = { rows: IOrderProduct[] }
const TableOrderProduct: FC<ITableOrderProductProps> = ({ rows }: ITableOrderProductProps) => {
  const columns = ColumnTableOrder();

  return (
    <div className="order_product__table">
      <div className="order_table">
        <div>
          {columns.map((column, cIndex) => (
            <div key={`column_${cIndex}`} style={{ textAlign: column.align || 'left' }}>
              {column.title}
            </div>
          ))}
        </div>
        {rows.map((row: IOrderProduct, index: number) => (
          <div key={`row_${index}`} className="body__row">
            {columns.map((column, cRow) => (
              <div key={`key_${index}_${cRow}`} style={{ textAlign: column.align || 'left' }}>
                {column.render ? column.render(row, index) : column.index ? row[column.index] : ''}
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

export default TableOrderProduct;

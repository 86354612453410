import {
  FC, useCallback, useEffect, useState,
} from 'react';
import { Button, Input, InputNumber } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import './index.scss';
import { useHistory } from 'react-router';
import { debounce } from 'lodash';
import { IProduct } from '../../interface/Product';
import TableProduct from '../../components/TableProduct';
import productHook from '../../hooks/product-hook';

export const productData: IProduct[] = [];

const MyShopPage: FC = () => {
  const router = useHistory();
  const {
    fetchProduct, products, total, minimumOrder, getMinimumOrder, updateMinimumOrder,
  } = productHook();
  const [page, _page] = useState<number>(1);
  const [srchText, _srchText] = useState('');

  const debouncedSearch = useCallback(
    debounce(async (query: number) => {
      updateMinimumOrder(query);
    }, 1000),
    [],
  );

  const fetchData = async () => {
    await fetchProduct(srchText, page, 10);
  };

  useEffect(() => {
    getMinimumOrder();
  }, []);

  const loadmore = () => {
    const nextPage = page + 1;
    _page(nextPage);
  };

  const searchProduct = useCallback(
    debounce(async (query: string) => {
      _srchText(query);
      _page(1);
    }, 1000),
    [],
  );

  useEffect(() => {
    fetchData();
  }, [srchText, page]);

  return (
    <div className="shop__page">
      <div>
        <div className="btn_add_section">
          <Button onClick={() => router.push('/shop/form')}>
            +เพิ่มสินค้า
          </Button>
        </div>
        <h1>My Shop</h1>
        <div className="input__section">
          <div>
            *Minimum shop order
          </div>
          <InputNumber style={{ width: '100%' }} onChange={debouncedSearch} value={minimumOrder} />
        </div>
        <div>
          <Input
            onChange={(e) => {
              searchProduct(e.target.value);
            }}
            style={{ borderColor: '#d9d9d9', boxShadow: 'none', marginBottom: 18 }}
            prefix={<SearchOutlined />}
            placeholder="Search product"
          />
        </div>
        <TableProduct rows={products} />
        {total > products.length ? (
          <div className="text-center">
            <Button onClick={loadmore} className="load__more__btn">Load more...</Button>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default MyShopPage;

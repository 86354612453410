/* eslint-disable max-len */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/destructuring-assignment */
import { Button } from 'antd';
import { useHistory } from 'react-router';
import OrderStatusBadge from '../order_status';
import { IColumnTableOrderProp, IOrder } from '../../interface/Order';
import { formatDateTime } from '../../common/hepler';

type IColumn = {
  onShippingClick: (orderId: string) => void
}
const ColumnTableOrder = ({ onShippingClick }: IColumn): IColumnTableOrderProp[] => [
  {
    title: 'Order ID',
    index: '_id',
  },
  {
    title: 'Create Date/Time',
    index: 'createdAt',
    render: (props: IOrder) => (
      <div>
        {formatDateTime(props.createdAt)}
      </div>
    ),
  },
  {
    title: 'Customer',
    index: 'retailerStoreName',
  },
  {
    title: 'Status',
    index: 'orderStatus',
    render: (props: IOrder) => <OrderStatusBadge orderId={props._id} orderStatus={props.orderStatus} onShippingClick={onShippingClick} />,
    align: 'center',
    width: '210px',
  },
  {
    title: '',
    index: 'totalPrice',
    align: 'center',
    width: '120px',
    render: (props: IOrder) => {
      const router = useHistory();
      return (
        <div>
          <Button
            onClick={() => router.push(`/receipt/${props._id}`)}
            size="small"
            style={{
              width: '93px',
              backgroundColor: '#979797',
              borderColor: '#979797',
              borderRadius: '0px',
              color: 'white',
              fontSize: '12px',
            }}
          >
            Receipt
          </Button>
        </div>
      );
    },
  },
];

export default ColumnTableOrder;

import { AxiosResponse } from 'axios';
import { IPaymentInfo } from '../interface/Payment';
import $axios from '../plugin/axios';

export const getPaymentInfo = async (): Promise<AxiosResponse<IPaymentInfo[]>> => {
  const response = await $axios.get<IPaymentInfo[]>('/payment-info');
  console.log('response service => ', response);
  return response;
};

export const addPaymentInfo = async (form:IPaymentInfo): Promise<AxiosResponse<IPaymentInfo>> => {
  const response = await $axios.post<IPaymentInfo>('/payment-info', form);
  return response;
};

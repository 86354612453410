/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import { formatMoney } from '../../common/hepler';
import { IColumnTableOrderProductProp, IOrderProduct } from '../../interface/Order';
import './index.scss';

const ColumnTableOrder = (): IColumnTableOrderProductProp[] => [
  {
    title: 'สินค้า',
    width: '160px',
    render: (props) => {
      const imageProduct = typeof (props.variantSaleInfo.image) === 'string' ? props.variantSaleInfo.image : props.variantSaleInfo.image?.url || '';
      return (
        <div className="column_order_product">
          <div className="image_product" style={{ backgroundImage: `url(${imageProduct})` }} />
          <div className="detail">
            <div>
              {props.product.product}
            </div>
            <div className="product__detail">
              <div>
                {props.variantSaleInfo.var1.name}
              </div>
              <div>
                {props.variantSaleInfo.var2?.name || ''}
              </div>
            </div>
          </div>
        </div>
      );
    },
  },
  {
    title: 'จำนวน',
    index: 'amount',
    align: 'center',
  },
  {
    title: 'หน่วย',
    align: 'center',
    render: (props: IOrderProduct) => (
      <div>
        {props.variantSaleInfo.unit.name}
      </div>
    ),
  },
  {
    title: 'ราคา',
    align: 'center',
    render: (props: IOrderProduct) => (
      <div>
        $
        {formatMoney(props.variantSaleInfo.price)}
      </div>
    ),
  },
  {
    title: 'ราคารวม',
    align: 'center',
    render: (props: IOrderProduct) => (
      <div>
        $
        {formatMoney(props.productTotalPrice)}
      </div>
    ),
  },
];

export default ColumnTableOrder;

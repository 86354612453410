import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IPaymentInfo } from '../../interface/Payment';

// Define a type for the slice state
interface PaymentInfoState {
  paymentInfo: IPaymentInfo[];
  isLoading: boolean
}

// Define the initial state using that type
const initialState: PaymentInfoState = {
  paymentInfo: [],
  isLoading: false,
};

export const paymentInfoSlice = createSlice({
  name: 'paymentInfo',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setPaymentInfo(state, action: PayloadAction<IPaymentInfo[]>) {
      return {
        ...state,
        paymentInfo: action.payload,
      };
    },
    setLoading(state, action: PayloadAction<boolean>) {
      return {
        ...state,
        isLoading: action.payload,
      };
    },
  },
});

export const { setPaymentInfo, setLoading } = paymentInfoSlice.actions;

export default paymentInfoSlice.reducer;

/* eslint-disable consistent-return */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable @typescript-eslint/no-shadow */
import {
  Switch, BrowserRouter, Redirect, Route,
} from 'react-router-dom';
import './App.scss';
import RouteWithLayout from './hoc/route-with-layout';
import Order from './containers/Order';
import ShippingPage from './containers/Shipping';
import MyShopPage from './containers/MyShop';
import ConfirmOrder from './containers/Order/confirm-order';
import ConfirmPayment from './containers/Order/confirm-payment';
import ShopFormPage from './containers/MyShop/form';
import LoadingApp from './components/loadingApp';
import { useAppSelector } from './reducers/store';
import LoginPage from './containers/Login';
import ReceiptPage from './containers/Receipt';

const App = (): JSX.Element => {
  const isLoading = useAppSelector((state) => {
    let k: keyof typeof state;
    const isLoading = [];
    for (k in state) {
      const value = state[k];
      isLoading.push(value?.isLoading);
    }
    return isLoading.some((q) => q === true);
  });
  return (
    <div>
      <BrowserRouter>
        <Switch>
          <Redirect exact path="/" to="/order" />
          <RouteWithLayout exact path="/order" component={Order} />
          <Route exact path="/login" component={LoginPage} />
          <RouteWithLayout exact path="/order/confirm-order/:id" component={ConfirmOrder} />
          <RouteWithLayout exact path="/order/confirm-payment/:id" component={ConfirmPayment} />
          <RouteWithLayout exact path="/shipping" component={ShippingPage} />
          <RouteWithLayout exact path="/shop" component={MyShopPage} />
          <RouteWithLayout exact path="/shop/form" component={ShopFormPage} />
          <RouteWithLayout exact path="/shop/form/:productId" component={ShopFormPage} />
          <RouteWithLayout exact path="/receipt/:orderId" component={ReceiptPage} />
        </Switch>
      </BrowserRouter>
      {isLoading ? <LoadingApp /> : null}
    </div>
  );
};

export default App;

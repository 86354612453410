/* eslint-disable max-len */
import { Modal, Input, Button } from 'antd';
import { FC, useState } from 'react';

import './index.scss';

const { TextArea } = Input;

type IModalCancelOrder = {
    visible: boolean
    handleOk: (reason:string) => void
    handleCancel: () => void
}

const ModalCancelOrder:FC<IModalCancelOrder> = ({ visible, handleOk, handleCancel }:IModalCancelOrder) => {
  const [reason, _reason] = useState<string>();
  const [errMsg, _errMsg] = useState<boolean>(false);
  const onSubmit = () => {
    if (reason === undefined || reason === '') {
      _errMsg(true);
      return;
    }
    handleOk(reason);
  };
  return (
    <>
      <Modal title={null} footer={false} closable={false} visible={visible} onCancel={handleCancel} bodyStyle={{ padding: '20px 47px' }}>
        <div className="modal__cancel">
          <h1>
            Cancel order
          </h1>
          <p>
            Please tell us the reason why you reject this order.
          </p>
          <TextArea rows={3} value={reason} onChange={(e) => _reason(e.target.value)} onKeyUp={(e) => _errMsg(false)} />
          {errMsg ? <div className="error__input__text" style={{ textAlign: 'left' }}>This field is required</div> : null}
          <Button className="btn_confirm" onClick={onSubmit}>
            Confirm
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default ModalCancelOrder;

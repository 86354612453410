/* eslint-disable react/require-default-props */
/* eslint-disable react/no-array-index-key */
import { FC } from 'react';
import ColumnTableOrder from './column';
import { IOrder } from '../../interface/Order';
import './index.scss';

type ITableOrderProps = { rows: IOrder[], onShippingClick?: (orderId: string) => void }
const TableOrder: FC<ITableOrderProps> = ({ rows, onShippingClick }: ITableOrderProps) => {
  const shippingClick = (orderId: string) => {
    if (onShippingClick) {
      onShippingClick(orderId);
    }
  };
  const columns = ColumnTableOrder({ onShippingClick: onShippingClick || shippingClick });

  return (
    <div className="order__table">
      <table>
        <thead>
          <tr>
            {columns.map((column, cIndex) => (
              <th key={`column_${cIndex}`} style={{ flex: column.width ? `0 0 ${column.width}` : '1', textAlign: column.align || 'left' }}>
                {column.title}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {rows.length > 0 ? rows.map((row: IOrder, index: number) => (
            <tr key={`row_${index}`}>
              {columns.map((column, cRow) => (
                <td key={`key_${index}_${cRow}`} style={{ flex: column.width ? `0 0 ${column.width}` : '1', textAlign: column.align || 'left' }}>
                  {column.render ? column.render(row, index) : row[column.index]}
                </td>
              ))}
            </tr>
          )) : (
            <tr>
              <td> no data...</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default TableOrder;

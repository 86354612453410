/* eslint-disable no-console */
import {
  FC, useCallback, useEffect, useState,
} from 'react';
import { Button, Input, Tabs } from 'antd';
import './index.scss';
import { SearchOutlined } from '@ant-design/icons';
import { debounce } from 'lodash';
import orderHook from '../../hooks/order-hook';
import TableShipping from '../../components/TableShipping';

const { TabPane } = Tabs;

const Shipping: FC = () => {
  const [queryStatus, _queryStatus] = useState<string>('all');
  const [queryString, _queryString] = useState<string>('');
  const [page, _page] = useState<number>(1);

  const {
    fetchShipping, fetchShippingByStatus, orders, shippingOrder, total,
  } = orderHook();

  const fetchData = async () => {
    if (queryStatus === 'all') {
      await fetchShipping(queryString, page, 10);
    } else {
      await fetchShippingByStatus(queryStatus, queryString, page, 10);
    }
  };

  const debouncedSearch = useCallback(
    debounce(async (query: string) => {
      _queryString(query);
    }, 1000),
    [],
  );

  const callback = (key: string) => {
    _queryStatus(key);
  };

  const onShipping = async (orderId: string) => {
    try {
      await shippingOrder(orderId);
      if (page > 1) {
        _page(1);
      } else {
        await fetchData();
      }
    } catch (err) { console.log(err); }
  };

  useEffect(() => {
    fetchData();
  }, [page, queryString, queryStatus]);

  useEffect(() => {
    _page(1);
  }, [queryString, queryStatus]);

  const loadmore = () => {
    const nextPage = page + 1;
    _page(nextPage);
  };

  return (
    <div className="shipping__page">
      <div>
        <h1>Shipping</h1>
        <div className="input__section">
          <Input placeholder="Search orders" prefix={<SearchOutlined />} onChange={(e: { target: { value: string; }; }) => debouncedSearch(e.target.value)} />
        </div>
        <Tabs defaultActiveKey="all" onChange={callback} tabBarGutter={0}>
          <TabPane tab="All" key="all">
            <TableShipping rows={orders} onShippingClick={onShipping} />
          </TabPane>
          <TabPane tab="Prepare shipping" key="3">
            <TableShipping rows={orders} onShippingClick={onShipping} />
          </TabPane>
          <TabPane tab="Shipped" key="4">
            <TableShipping rows={orders} />
          </TabPane>
          <TabPane tab="Received" key="5">
            <TableShipping rows={orders} />
          </TabPane>
          <TabPane tab="Complete" key="6">
            <TableShipping rows={orders} />
          </TabPane>
        </Tabs>
        {total > orders.length ? (
          <div className="text-center">
            <Button onClick={loadmore} className="load__more__btn">Load more...</Button>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default Shipping;

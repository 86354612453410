/* eslint-disable consistent-return */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-alert */
/* eslint-disable max-len */
import {
  Button, Form, Input, Modal, Radio,
} from 'antd';
import { FC, useEffect } from 'react';
import { Redirect, Router, useHistory } from 'react-router';
import AccountEmailIcon from '../../common/icons/account_email';
import AccountPasswordIcon from '../../common/icons/account_icon';
import LogoIcon from '../../common/icons/logo_icon';
import AuthHook from '../../hooks/auth-hook';
import { IUserLogin } from '../../interface/User';
import './index.scss';

const LoginPage: FC = () => {
  const router = useHistory();
  const { login } = AuthHook();

  const accessToken = localStorage.getItem('accessToken');

  useEffect(() => {
    console.log('accessToken =>', accessToken);
    if (accessToken !== null) {
      router.replace('/');
    }
  }, []);

  const [form] = Form.useForm();

  const onFinish = async (values: IUserLogin) => {
    try {
      await login(values);
      router.replace('/');
    } catch (err) {
      console.log(err);
      alert('Login failed');
      form.resetFields();
    }
  };

  const onFinishFailed = async () => {
    console.log('failed');
  };

  console.log(process.env.REACT_APP_WHOLESALER_URL);

  return (
    <div className="login_page">
      <div className="login_modal">
        <LogoIcon />
        <h2>เข้าสู่ระบบ</h2>
        <p>สินค้าราคาส่งมากมายรอคุณอยู่</p>
        <Form
          name="basic"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          form={form}
        >
          <Form.Item
            name="email"
            rules={[
              {
                type: 'email',
                message: 'กรุณากรอก Email ให้ถูกต้อง !',
              },
              {
                required: true,
                message: 'กรุณากรอก Email !',
              },
            ]}
          >
            <Input
              size="large"
              placeholder="Enter your email"
              className="input_login"
              prefix={<AccountEmailIcon />}
            />
          </Form.Item>

          <Form.Item
            name="password"
            rules={[{ required: true, message: 'กรุณากรอกรหัสผ่าน' }]}
          >
            <Input.Password
              size="large"
              placeholder="Password"
              className="input_login"
              prefix={<AccountPasswordIcon />}
            />
          </Form.Item>
          <div className="remember_section">
            <Radio>Remember me</Radio>
          </div>
          <Button htmlType="submit" className="login_btn">
            เข้าสู่ระบบแบบร้านค้า
          </Button>
          <div className="whole_btn">
            <a href={process.env.REACT_APP_RETAILER_URL} target="_blank">
              คลิกที่นี่เพื่อเข้าสู่ระบบแบบลูกค้า
            </a>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default LoginPage;

/* eslint-disable react/jsx-props-no-spreading */
import {
  ComponentType, FC,
} from 'react';
import { Redirect, Route } from 'react-router-dom';
import TopNavigation from '../../components/TopNavigation';
import AuthHook from '../../hooks/auth-hook';

import './index.scss';

type ILayoutProp = { children: ComponentType }

const Layout: FC<ILayoutProp> = ({ children: Content }: ILayoutProp) => (
  <div className="layout__component">
    <TopNavigation />
    <section>
      <Content />
    </section>
  </div>
);

type IRouteWithLayoutProp = {
  component: React.ComponentType<unknown>,
  path: string
  // eslint-disable-next-line react/require-default-props
  exact?: boolean
}

// eslint-disable-next-line max-len
const RouteWithLayout: FC<IRouteWithLayoutProp> = ({ component, path, exact }: IRouteWithLayoutProp) => {
  const { accessToken } = AuthHook();
  if (!accessToken) {
    return (
      <Redirect to="/login" />
    );
  }
  return (
    <Route
      exact={exact || false}
      path={path}
    >
      <Layout>
        {component}
      </Layout>
    </Route>
  );
};

export default RouteWithLayout;

export type INavMenu = {
  title: string;
  route: string;
};

export const menu: INavMenu[] = [
  {
    title: 'Orders',
    route: '/order',
  },
  {
    title: 'Shipping',
    route: '/shipping',
  },
  {
    title: 'My Shop',
    route: '/shop',
  },
  // {
  //   title: 'Analytics',
  //   route: '/analytic',
  // },
];

/* eslint-disable max-len */
import { AxiosResponse } from 'axios';
import { ICountry, IProvince } from '../interface/Address';
import { IUserLogin, IUserToken } from '../interface/User';
import $axios from '../plugin/axios';

export const loginService = async (form:IUserLogin):Promise<AxiosResponse<IUserToken>> => {
  const response = await $axios.post('/auth/signin-wholesaler', form);
  return response;
};

export const logoutService = async ():Promise<AxiosResponse | undefined> => {
  try {
    const response = await $axios.post('/auth/signout-wholesaler', { refreshToken: localStorage.getItem('refreshToken') });
    return response;
  } catch (err) {
    return undefined;
  }
};

export const refreshTokenService = async (accessToken:string):Promise<AxiosResponse> => {
  const response = $axios.get('/auth/renew-wholesaler', {
    data: {
      accessToken,
    },
  });
  return response;
};

export const getCountry = async ():Promise<AxiosResponse<ICountry[]>> => {
  const response = await $axios.get('/country');
  return response;
};

export const getProvince = async (countryId:string):Promise<AxiosResponse<IProvince[]>> => {
  const response = await $axios.get(`/province/${countryId}`);
  return response;
};

/* eslint-disable import/prefer-default-export */
import { AxiosResponse } from 'axios';
import { IMinimumOrder } from '../interface/Product';
import $axios from '../plugin/axios';

export const get = async ():Promise<AxiosResponse<IMinimumOrder>> => {
  const response = $axios.get('/wholesaler/minimum-order');
  return response;
};

export const update = async (minimumOrder:number):Promise<AxiosResponse> => {
  const response = $axios.put('/wholesaler/minimum-order', { minimumOrder });
  return response;
};

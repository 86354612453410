/* eslint-disable import/prefer-default-export */
/* eslint-disable max-len */
import { AxiosResponse } from 'axios';
import {
  ICategory, ICreateProduct, IProduct, IProductDetail, IProductResponse, IUnit, IUpdateProduct,
} from '../interface/Product';
import $axios from '../plugin/axios';

export const getProduct = (
  keyword: string,
  page: number,
  size: number,
): Promise<AxiosResponse<IProductResponse>> => {
  const url = `/product/wholesaler?keyword=${keyword}&page=${page}&size=${size}`;
  const response = $axios.get(url);
  return response;
};

export const findProduct = (id: string): Promise<AxiosResponse<IProductDetail>> => {
  const response = $axios.get(`/product/wholesaler-detail/${id}`);
  return response;
};

export const create = (form: ICreateProduct): Promise<AxiosResponse<IProduct>> => {
  const response = $axios.post('/product/wholesaler', form);
  return response;
};

export const update = (form: IUpdateProduct): Promise<AxiosResponse<IProduct>> => {
  const response = $axios.put('/product/wholesaler', form);
  return response;
};

export const getCategory = (): Promise<AxiosResponse<ICategory[]>> => {
  const response = $axios.get('/category');
  return response;
};

export const getUnit = (): Promise<AxiosResponse<IUnit[]>> => {
  const reponse = $axios.get('/unit');
  return reponse;
};

/* eslint-disable no-underscore-dangle */
import { FC, useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';

import {
  Button, Col, Row, Steps,
} from 'antd';
import './index.scss';
import ArrowBackIcon from '../../common/icons/arrow_back';
import { IOrderDetail } from '../../interface/Order';
import ModalCancelOrder from '../../components/ModalCancelOrder';
import orderHook from '../../hooks/order-hook';

const { Step } = Steps;

type ConfirmPaymentParams = {
    id:string
}
const ConfirmPayment:FC = () => {
  const { id } : ConfirmPaymentParams = useParams();
  const router = useHistory();
  const [modalCancel, setModalCancel] = useState(false);
  const { fetchOrderDetail, confirmPayment } = orderHook();
  const [orderByID, _orderByID] = useState<IOrderDetail>();

  const fetchData = async () => {
    const data = await fetchOrderDetail(id);
    if (data && data.orderStatus === '2') {
      _orderByID(data);
    } else {
      router.goBack();
    }
  };

  useEffect(() => {
    fetchData();
  }, [id]);

  const cancelOrder = () => {
    router.push('/order');
  };

  const renderDate = (str:string) => {
    const dd = str.substring(0, 2);
    const mm = str.substring(2, 4);
    const yy = str.substring(4, 8);
    return `${dd}/${mm}/${yy}`;
  };

  const renderTime = (str:string) => {
    const hh = str.substring(0, 2);
    const mm = str.substring(2, 4);
    return `${hh}:${mm}`;
  };

  const onConfirmOrder = async () => {
    if (orderByID?._id) {
      try {
        await confirmPayment(orderByID?._id);
        router.push('/shipping');
      } catch (err) { console.log(err); }
    }
  };

  const stepBody = () => (
    <Row gutter={[12, 36]} className="body">
      <Col span="24" className="title_page">
        <div>
          Please check the transaction and evidence carefully
        </div>
        <div>
          This is custom content from ticket page setting page in the admin
        </div>
      </Col>
      <Col span="24">
        <div className="subtitle_section">
          Payment Information
        </div>
      </Col>
      <Col span="24">
        <Row gutter={[27, 27]}>
          <Col span={24} xs={24} md={14}>
            <Row gutter={[0, 42]}>
              <Col span={24}>
                <div className="box__detail">
                  <div className="box__head">1. SHIPPING INFO</div>
                  <div className="box__body">
                    <div>
                      {orderByID?.shippingName}
                    </div>
                    <div>
                      Telephone number:
                      {' '}
                      {orderByID?.shippingPhoneNumber}
                    </div>
                  </div>
                </div>
              </Col>
              <Col span={24}>
                <div className="box__detail">
                  <div className="box__head">2. PRICE SUMMARY</div>
                  <div className="box__body">
                    <Row justify="space-between">
                      <Col>Product</Col>
                      <Col>
                        {orderByID?.orders.reduce((prev, cur) => prev + cur.productTotalPrice, 0)}
                        $
                      </Col>
                    </Row>
                    <Row justify="space-between" style={{ marginTop: 9 }}>
                      <Col>Shipping</Col>
                      <Col>
                        {orderByID?.shippingFee}
                        $
                      </Col>
                    </Row>
                    <Row justify="space-between" style={{ fontWeight: 500, marginTop: 12 }}>
                      <Col>Total:</Col>
                      <Col>
                        {' '}
                        {orderByID?.totalPrice}
                        $
                      </Col>
                    </Row>
                  </div>
                </div>
              </Col>
              <Col span={24}>
                <div className="box__detail">
                  <div className="box__head">3. PAYMENT</div>
                  <div className="box__body">
                    <div>
                      {orderByID?.paymentInfo.brandName}
                    </div>
                    <div>
                      Bank account number :
                      {' '}
                      {orderByID?.paymentInfo.accountNumber}
                    </div>
                    <div>
                      Branch :
                      {' '}
                      {orderByID?.paymentInfo.branch}
                    </div>
                    <div>
                      Account name :
                      {' '}
                      {orderByID?.paymentInfo.accountName}
                    </div>
                    <div>
                      Date :
                      {' '}
                      {renderDate(orderByID?.transferDate || '00000000')}
                    </div>
                    <div>
                      Time :
                      {' '}
                      {renderTime(orderByID?.transferTime || '0000')}
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Col>
          <Col span={24} xs={24} md={10}>
            <img src={orderByID?.informPaymentSlip.url} alt="slip" style={{ width: '100%', height: 'auto' }} />
          </Col>
        </Row>
      </Col>
      <Col span={24} style={{ textAlign: 'center' }}>
        <Button className="confirm_btn" onClick={onConfirmOrder}>Confirm</Button>
      </Col>
    </Row>
  );

  console.log('orderByID => ', orderByID);

  return (
    <div className="confirm_layout confirm__payment__page">
      <div>
        <Button onClick={() => router.goBack()} ghost>
          <ArrowBackIcon />
        </Button>
        <Steps size="small" current={2} labelPlacement="vertical">
          <Step subTitle="Confirm order" />
          <Step subTitle="Wait for payment" />
          <Step subTitle="Confirm payment" />
        </Steps>
        <div />
      </div>

      {stepBody()}

      <ModalCancelOrder
        visible={modalCancel}
        handleOk={cancelOrder}
        handleCancel={() => setModalCancel(false)}
      />

    </div>
  );
};

export default ConfirmPayment;

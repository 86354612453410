import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IOrder, IOrderResponse } from '../../interface/Order';

// Define a type for the slice state
interface OrderState {
  orders: IOrder[];
  total:number
  isLoading: boolean
}

// Define the initial state using that type
const initialState: OrderState = {
  orders: [],
  total: 0,
  isLoading: false,
};

export const orderSlice = createSlice({
  name: 'order',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setOrder(state, action: PayloadAction<IOrderResponse>) {
      return {
        ...state,
        orders: action.payload.docs,
        total: action.payload.total,
      };
    },
    addOrder(state, action: PayloadAction<IOrderResponse>) {
      const addOrders = [...state.orders, ...action.payload.docs];
      console.log('addOrders => ', addOrders);
      return {
        ...state,
        orders: addOrders,
        total: action.payload.total,
      };
    },
    setLoading(state, action: PayloadAction<boolean>) {
      return {
        ...state,
        isLoading: action.payload,
      };
    },
  },
});

export const { setOrder, addOrder, setLoading } = orderSlice.actions;

export default orderSlice.reducer;

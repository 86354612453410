/* eslint-disable no-plusplus */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-underscore-dangle */
/* eslint-disable max-len */
import { FC, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import {
  Button,
  Col, Divider, Input, InputNumber, Row, Select, Upload,
} from 'antd';
import { DeleteFilled, PlusOutlined } from '@ant-design/icons';
import {
  Control, Controller, useForm,
} from 'react-hook-form';
import BreadcrumbComponent, { IRouteProps } from '../../components/Breadcrumb';
import './index.scss';
// eslint-disable-next-line import/order
import { UploadChangeParam, UploadFile } from 'antd/lib/upload/interface';
import productHook from '../../hooks/product-hook';
import {
  ICategory, ICreateProduct, IProductDetail, IUnit, IUpdateProduct, IVariantListItem, IVariantSaleInfo,
} from '../../interface/Product';
import * as mediaService from '../../services/media-service';

const { Option } = Select;

const { TextArea } = Input;

type IFeature = {
  name: string;
  list: IVariantListItem[];
}

type IFeatureComponent = {
  onChange: (data: IFeature | null | undefined) => void
  componentKey: string,
  value: IFeature | null | undefined
}

const FeatureCreation: FC<IFeatureComponent> = ({ onChange, componentKey, value }: IFeatureComponent) => {
  const [featureName, setFeatureName] = useState<string>('');
  const [varient, setVarient] = useState<IVariantListItem[]>([]);

  const add = () => {
    const nowField = [...varient];
    nowField.push({
      index: varient.length,
      detail: '',
    });
    setVarient(nowField);
  };

  const featureChange = (e: any) => {
    setFeatureName(e.target.value);
    if (e.target.value === '') {
      setVarient([]);
    }
  };

  const varientChange = (e: any, index: number) => {
    const updateItem = [...varient];
    updateItem[index] = {
      index,
      detail: e.target.value,
    };
    setVarient(updateItem);
  };

  useEffect(() => {
    let data: IFeature | undefined | null;
    if (featureName === '' || varient.length <= 0) {
      data = undefined;
    } else {
      data = {
        name: featureName,
        list: varient.filter((q) => q.detail !== ''),
      };
    }
    onChange(data);
  }, [varient, featureName]);

  useEffect(() => {
    if (value) {
      setVarient(value.list);
      setFeatureName(value.name);
    }
  }, [value]);

  return (
    <Row gutter={[24, 24]} align="top">
      <Col xs={24} md={8}>Feature name</Col>
      <Col xs={24} md={16}><Input size="middle" onChange={featureChange} value={featureName} /></Col>
      <Col xs={24} md={8}>Variant</Col>
      <Col xs={24} md={16}>
        <Row gutter={[24, 24]}>
          {varient.map((f, index) => (
            <Col span={24} key={`${String(index)}__${componentKey}`}>
              <Input
                size="middle"
                onChange={(e) => {
                  varientChange(e, index);
                }}
                value={varient[index].detail}
              />
            </Col>
          ))}
          <Col span={24}>
            <Button size="middle" style={{ width: '100%' }} type="dashed" onClick={add} icon={<PlusOutlined />} />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

const uploadButton = (
  <div className="uploadButton">
    <PlusOutlined style={{ color: '#979797' }} />
  </div>
);

type IInformationCreation = {
  feature1Option: IFeature | undefined | null
  feature2Option: IFeature | undefined | null
  index: number
  control: Control<ICreateProduct | IUpdateProduct>
  units: IUnit[]
  subFileList: UploadFile<any>[]
  setSubFileList: React.Dispatch<React.SetStateAction<UploadFile<any>[]>>
}

const InformationCreation: FC<IInformationCreation> = ({
  feature1Option, feature2Option, index, control, units, subFileList, setSubFileList,
}: IInformationCreation) => {
  const [firstState, setFirst] = useState(false);
  const [fileInformationList, setFileInformationList] = useState<UploadFile[]>([]);

  const handleChangeImageInformation = (e: UploadChangeParam<any>) => {
    setFileInformationList(e.fileList);
    const updateSubFile = [...subFileList];
    const file = e.fileList[0];
    if (file) {
      updateSubFile[index] = file;
    }
    setSubFileList(updateSubFile);
  };

  useEffect(() => {
    if (subFileList.length > 0 && subFileList[index] !== undefined && !firstState) {
      setFileInformationList([subFileList[index]]);
      setFirst(true);
    }
  }, [subFileList]);

  return (
    <Row gutter={[24, 24]} align="middle">
      <Col xs={24} md={6} className="text-w-500">
        ตัวเลือกสินค้า
      </Col>
      <Col xs={24} md={18}>
        <Row gutter={[24, 12]}>
          <Col span={12} xs={12} md={10}>
            <div style={{ marginBottom: 4 }}>
              {feature1Option ? feature1Option.name : 'Feature name'}
            </div>
            <Controller
              control={control}
              name={`variantSaleInfo.${index}.var1`}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <>
                  <Select placeholder="Please select" size="large" style={{ width: '100%' }} options={feature1Option?.list} fieldNames={{ label: 'detail', value: 'index' }} onChange={onChange} value={value} />
                  {error ? <div className="error__input__text">{error.message}</div> : null}
                </>
              )}
              rules={{ required: 'This field is required.' }}
            />
          </Col>
          <Col span={12} xs={12} md={10}>
            <div style={{ marginBottom: 4 }}>
              {feature2Option?.name || 'Feature name'}
            </div>
            <Controller
              defaultValue={null}
              control={control}
              name={`variantSaleInfo.${index}.var2`}
              render={({ field: { onChange, value } }) => (
                <>
                  <Select placeholder="Please select" size="large" style={{ width: '100%' }} options={feature2Option?.list} fieldNames={{ label: 'detail', value: 'index' }} onChange={onChange} value={value} />
                </>
              )}
            />
          </Col>
        </Row>
      </Col>
      <Col xs={24} md={6} className="text-w-500">
        ภาพสินค้า
      </Col>
      <Col xs={24} md={18}>
        <Controller
          control={control}
          name={`variantSaleInfo.${index}.image`}
          render={({ field: { onChange }, fieldState: { error } }) => (
            <>
              <Upload
                accept="image/*"
                listType="picture-card"
                fileList={fileInformationList}
                onChange={(e: UploadChangeParam<any>) => {
                  handleChangeImageInformation(e);
                  onChange(e.fileList[0] ? e.fileList[0].uid : undefined);
                }}
                beforeUpload={() => false}
              >
                {fileInformationList.length > 0 ? null : uploadButton}
              </Upload>
              {error ? <div className="error__input__text">{error.message}</div> : null}
            </>
          )}
          rules={{ required: 'This field is required.' }}
        />
      </Col>
      <Col xs={24} md={6} className="text-w-500">
        ราคา
      </Col>
      <Col xs={24} md={18}>
        <Row gutter={[12, 12]}>
          <Col span={6}>
            <Controller
              control={control}
              name={`variantSaleInfo.${index}.unit`}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <>
                  <Select placeholder="Unit of price" size="large" onChange={onChange} value={value} style={{ width: '100%' }} options={units} fieldNames={{ label: 'name', value: '_id' }} />
                  {error ? <div className="error__input__text">{error.message}</div> : null}
                </>
              )}
              rules={{ required: 'This field is required.' }}
            />
          </Col>
          <Col span={18}>
            <Controller
              control={control}
              name={`variantSaleInfo.${index}.price`}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <>
                  <InputNumber style={{ width: '100%' }} size="large" onChange={onChange} value={value} />
                  {error ? <div className="error__input__text">{error.message}</div> : null}
                </>
              )}
              rules={{ required: 'This field is required.' }}
            />
          </Col>
        </Row>
      </Col>
      <Col xs={24} md={6} className="text-w-500">
        คลัง
      </Col>
      <Col xs={24} md={18}>
        <Controller
          control={control}
          name={`variantSaleInfo.${index}.stock`}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <>
              <InputNumber style={{ width: '100%' }} size="large" onChange={onChange} value={value} />
              {error ? <div className="error__input__text">{error.message}</div> : null}
            </>
          )}
          rules={{ required: 'This field is required.' }}
        />
      </Col>
      <Col xs={24} md={6} className="text-w-500">
        Minimum order
      </Col>
      <Col xs={24} md={18}>
        <Controller
          control={control}
          name={`variantSaleInfo.${index}.minimumOrder`}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <>
              <InputNumber style={{ width: '100%' }} size="large" onChange={onChange} value={value} />
              {error ? <div className="error__input__text">{error.message}</div> : null}
            </>
          )}
          rules={{ required: 'This field is required.' }}
        />
      </Col>
    </Row>
  );
};

const tagRender = (props: any) => {
  const {
    // eslint-disable-next-line react/prop-types
    label, onClose,
  } = props;

  return (
    <div className="ant-select-selection-item">
      {label}
      <Button size="small" type="text" style={{ marginLeft: 4 }} onClick={onClose}>
        <svg width="9" height="9" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fillRule="evenodd" clipRule="evenodd" d="M5.90936 4.50001L8.1987 2.21067C8.38865 2.03072 8.49862 1.78079 8.49862 1.50087C8.49833 1.30295 8.43948 1.10953 8.32946 0.944995C8.21945 0.780458 8.06321 0.652159 7.88041 0.576261C7.69762 0.500363 7.49645 0.480262 7.30225 0.518489C7.10805 0.556716 6.9295 0.651561 6.78911 0.791077L4.49977 3.09042L2.21043 0.791077C2.02218 0.602828 1.76685 0.49707 1.50063 0.49707C1.2344 0.49707 0.979083 0.602828 0.790833 0.791077C0.602584 0.979327 0.496826 1.23465 0.496826 1.50087C0.496826 1.7671 0.602584 2.02242 0.790833 2.21067L3.09017 4.50001L0.800831 6.78935C0.610885 6.9693 0.500916 7.21923 0.500916 7.49915C0.501202 7.69708 0.560058 7.89049 0.67007 8.05503C0.780081 8.21956 0.936327 8.34786 1.11912 8.42376C1.30192 8.49966 1.50309 8.51976 1.69729 8.48153C1.89149 8.44331 2.07003 8.34846 2.21043 8.20895L4.49977 5.90961L6.78911 8.19895C6.96906 8.38889 7.21899 8.49886 7.49891 8.49886C7.69683 8.49858 7.89025 8.43972 8.05478 8.32971C8.21932 8.2197 8.34762 8.06345 8.42352 7.88066C8.49942 7.69786 8.51952 7.49669 8.48129 7.30249C8.44306 7.10829 8.34822 6.92975 8.2087 6.78935L5.90936 4.50001Z" fill="#080C16" />
        </svg>
      </Button>
    </div>
  );
};

const ShopFormPage: FC = () => {
  const router = useHistory();
  const { productId }: { productId: string } = useParams();

  const {
    fetchCategory, categorys, fetchUnit, units, createProduct, updateProduct, setLoading, findProduct,
  } = productHook();
  const {
    handleSubmit, control, getValues, setValue,
  } = useForm<ICreateProduct | IUpdateProduct>();

  const [productByID, setProductByID] = useState<IProductDetail>();
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [subFileList, setSubFileList] = useState<UploadFile[]>([]);
  const [videoList, setVideoList] = useState<UploadFile[]>([]);
  const [information, setInformation] = useState([{}]);
  const [var1Option, setVar1Option] = useState<IFeature | null | undefined>();
  const [var2Option, setVar2Option] = useState<IFeature | null | undefined>(null);

  const [initVal1, setInitVal1] = useState<IFeature | null | undefined>();
  const [initVal2, setInitVal2] = useState<IFeature | null | undefined>(null);

  const routes: IRouteProps[] = [];
  routes.push({ path: '/shop', title: 'My Shop' });
  if (productId) {
    routes.push({ path: `/shop/form/${productId}`, title: productByID?.product || '' });
  } else {
    routes.push({ path: '/shop/form', title: 'Add Product' });
  }

  const fetchData = async () => {
    await fetchCategory();
    await fetchUnit();
  };

  const handleChangeImage = (e: any) => {
    setFileList(e.fileList);
  };

  const handleChangeVideo = (e: any) => {
    setVideoList(e.fileList);
  };

  const addInformation = () => {
    const xx = [...information];
    xx.push({});
    setInformation(xx);
  };

  const submitForm = async (data: ICreateProduct | IUpdateProduct) => {
    try {
      const formData = { ...data };
      const { images, variantSaleInfo } = formData;

      setLoading(true);

      // upload varient image
      for (let info = 0; info < variantSaleInfo.length; info++) {
        const image = subFileList[info]?.originFileObj;
        if (image) {
          const { data: { _id } } = await mediaService.uploadImage(image);
          variantSaleInfo[info].image = _id;
        }
      }

      // upload image
      for (let i = 0; i < images.length; i++) {
        const image = fileList[i]?.originFileObj;
        if (image) {
          const { data: { _id } } = await mediaService.uploadImage(image);
          formData.images[i] = _id;
        }
      }

      // upload video
      if (videoList[0]?.originFileObj) {
        const { data: { _id } } = await mediaService.uploadVideo(videoList[0]?.originFileObj);
        formData.video = _id;
      } else {
        formData.video = null;
      }

      if (productId) { // update
        const updateFormData = { productId, ...formData };
        console.log('update formData => ', updateFormData);
        await updateProduct(updateFormData);
      } else { // create
        console.log('create formData => ', formData);
        if (formData.var2 === undefined) {
          formData.var2 = null;
        }
        await createProduct(formData);
      }
      router.goBack();
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const deleteInformation = (index: number) => {
    try {
      const updateItem = [...getValues('variantSaleInfo')];
      updateItem.splice(index, 1);
      setInformation(updateItem);
      setValue('variantSaleInfo', updateItem);
    } catch (err) {
      console.log(err);
    }
  };

  const findDetailProduct = async (id: string) => {
    const data = await findProduct(id);
    if (data) {
      const var1 = {
        name: data.var1.name,
        list: data.var1.list.map((q) => {
          const obj = {
            index: q.index,
            detail: q.name,
          };
          return obj;
        }),
      };
      const var2 = data.var2 === null ? null : {
        name: data.var2.name,
        list: data.var2.list.map((q) => {
          const obj = {
            index: q.index,
            detail: q.name,
          };
          return obj;
        }),
      };
      const images = data.images.map((q) => {
        const obj: UploadFile = {
          uid: q._id,
          name: q._id,
          url: q.url,
        };
        return obj;
      });
      const subFiles = data.variantSaleInfo.map((q) => {
        const obj: UploadFile = {
          uid: q.image._id,
          name: q.image._id,
          url: q.image.url,

        };
        return obj;
      });

      setValue('product', data.product);
      setValue('description', data.description);
      setValue('images', data.images.map((q) => q._id));
      setValue('categories', data.categories.map((q) => q._id));
      setValue('var1', var1);
      setValue('var2', var2);
      setValue('variantSaleInfo', data.variantSaleInfo.map((q) => {
        const obj: IVariantSaleInfo = {
          var1: q.var1,
          var2: q.var2,
          image: q.image._id,
          unit: q.unit._id,
          price: q.price,
          stock: q.stock,
          minimumOrder: q.minimumOrder,
        };
        return obj;
      }));
      setVar1Option(var1);
      setVar2Option(var2);
      setInitVal1(var1);
      setInitVal2(var2);
      setInformation(data.variantSaleInfo);
      setProductByID(data);
      setFileList(images);
      if (data.video !== null) {
        setValue('video', data.video._id);
        const video = [{
          uid: data.video._id,
          name: data.video._id,
          type: 'file',
        }];
        setVideoList(video);
      }
      setSubFileList(subFiles);
    } else {
      router.goBack();
    }
  };

  useEffect(() => {
    if (productId) {
      findDetailProduct(productId);
    }
    fetchData();
  }, [productId]);

  const isLte5M = (size: number) => size / 1024 / 1024 <= 5;

  const isLte10M = (size: number) => size / 1024 / 1024 <= 10;

  const sizeCheck = (v: string[]) => {
    const checkFileList = fileList.map((img) => isLte5M(img.size || 0));
    return checkFileList.every((lte) => lte);
  };

  const sizeCheckVideo = () => {
    const checkFileList = videoList.map((img) => isLte10M(img.size || 0));
    return checkFileList.every((lte) => lte);
  };

  return (
    <div className="shop__form__page">
      <BreadcrumbComponent routes={routes} />
      <form onSubmit={handleSubmit(submitForm)}>
        <Row gutter={[0, 35]} className="shop__form__input">
          <Col span="24">
            <Row gutter={[24, 24]} align="middle">
              <Col xs={24} md={6} className="head-title-prod">
                Image
              </Col>
              <Col xs={24} md={18}>
                <Controller
                  defaultValue={[]}
                  control={control}
                  name="images"
                  render={({ field: { onChange, value }, fieldState: { error } }) => {
                    console.log(error);
                    return (
                      <>
                        <Upload
                          accept="image/*"
                          listType="picture-card"
                          fileList={fileList}
                          onChange={(e) => {
                            handleChangeImage(e);
                            onChange(e.fileList.map((m) => m.uid));
                          }}
                          beforeUpload={() => false}
                        >
                          {uploadButton}
                        </Upload>
                        {
                          error
                            ? (
                              <div className="error__input__text">
                                {error?.type === 'required' && 'This field is required.'}
                                {error?.type === 'lessThan' && 'This field is least 3 images.'}
                                {error?.type === 'geaterThan' && 'This field is max 10 images.'}
                                {error?.type === 'sizeOver' && 'File size cannot over 5MB'}
                              </div>
                            )
                            : null
                        }
                      </>
                    );
                  }}
                  rules={{
                    required: true,
                    validate: {
                      lessThan: (v) => v.length >= 3,
                      geaterThan: (v) => v.length <= 10,
                      sizeOver: (v) => sizeCheck(v),
                    },
                  }}

                />
              </Col>
              <Col xs={24} md={6} className="head-title-prod">
                Video
              </Col>
              <Col xs={24} md={18}>
                <Controller
                  control={control}
                  name="video"
                  render={({ field: { onChange }, fieldState: { error } }) => (
                    <>
                      <Upload
                        accept="video/*"
                        listType="picture-card"
                        fileList={videoList}
                        onChange={(e) => {
                          handleChangeVideo(e);
                          onChange(e.fileList.map((m) => m.uid));
                        }}
                        beforeUpload={() => false}
                      >
                        {videoList.length <= 0 ? uploadButton : null}
                      </Upload>
                      {error ? <div className="error__input__text">File size cannot over 10MB</div> : null}
                    </>
                  )}
                  rules={{
                    validate: {
                      sizeOver: (v) => sizeCheckVideo(),
                    },
                  }}
                />
              </Col>
              <Col xs={24} md={6} className="head-title-prod">
                Product
              </Col>
              <Col xs={24} md={18}>
                <Controller
                  control={control}
                  name="product"
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <>
                      <Input size="large" showCount maxLength={120} value={value} onChange={onChange} />
                      {
                        error
                          ? (
                            <div className="error__input__text">
                              {error?.type === 'required' && 'This field is required.'}
                              {error?.type === 'maxLength' && 'This field is max 120 char.'}
                            </div>
                          )
                          : null
                      }
                    </>
                  )}
                  rules={{ required: true, maxLength: 120 }}
                />
              </Col>
              <Col xs={24} md={6} className="head-title-prod">
                Product description
              </Col>
              <Col xs={24} md={18}>
                <Controller
                  control={control}
                  name="description"
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <>
                      <TextArea size="large" showCount rows={4} maxLength={5000} value={value} onChange={onChange} />
                      {
                        error
                          ? (
                            <div className="error__input__text">
                              {error?.type === 'required' && 'This field is required.'}
                              {error?.type === 'minLength' && 'This field is least 50 char.'}
                              {error?.type === 'maxLength' && 'This field is max 5000 char.'}
                            </div>
                          )
                          : null
                      }
                    </>
                  )}
                  rules={{ required: true, maxLength: 5000, minLength: 50 }}
                />
              </Col>
              <Col xs={24} md={6} className="head-title-prod">
                Catergory
              </Col>
              <Col xs={24} md={18}>
                <Controller
                  control={control}
                  name="categories"
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <>
                      <Select
                        size="large"
                        mode="multiple"
                        showArrow
                        tagRender={tagRender}
                        style={{ width: '100%' }}
                        onChange={onChange}
                        value={value}
                        className="item__tag"
                      >
                        {categorys.map((category: ICategory, index: number) => <Option key={`category__${String(index)}`} value={category._id}>{category.name}</Option>)}
                      </Select>
                      {error ? <div className="error__input__text">{error.message}</div> : null}
                    </>
                  )}
                  rules={{ required: 'This field is required.', maxLength: 5000 }}
                />
              </Col>
            </Row>
          </Col>
          <Col span="24">
            <Row gutter={[24, 24]} className="prod__feature">
              <Col span={24}>
                <h2>
                  Product features
                </h2>
              </Col>
              <Col span={24}>
                <p>
                  finished : 0/4 add feature information to make your product more visible.
                </p>
              </Col>
              <Col span={24} xs={24} md={12}>
                <Controller
                  control={control}
                  name="var1"
                  render={({ field: { onChange }, fieldState: { error } }) => (
                    <>
                      <FeatureCreation
                        onChange={(data) => {
                          onChange(data);
                          setVar1Option(data);
                        }}
                        key="var1"
                        componentKey="var1"
                        value={initVal1}
                      />
                      {error ? <div className="error__input__text">{error.message}</div> : null}
                    </>
                  )}
                  rules={{ required: 'This field is required.' }}
                />
              </Col>
              <Col span={24} xs={24} md={12}>
                <Controller
                  control={control}
                  name="var2"
                  defaultValue={null}
                  render={({ field: { onChange } }) => (
                    <FeatureCreation
                      onChange={(data) => {
                        onChange(data);
                        setVar2Option(data);
                      }}
                      key="var2"
                      componentKey="var2"
                      value={initVal2}
                    />
                  )}
                />
              </Col>
            </Row>
          </Col>
          <Col span="24">
            <Row gutter={[24, 24]} className="prod__information" justify="center">
              <Col span={24}>
                <h2>
                  sales Information
                </h2>
              </Col>
              {information.map((q, index: number) => (
                <Col span={24} key={`${String(index)}__information`}>
                  <Row gutter={[24, 24]} className="information__product">
                    {index > 0
                      ? <Button type="primary" icon={<DeleteFilled />} danger onClick={() => deleteInformation(index)} className="btn_del" />
                      : null}
                    <Col span={24} key={`${String(index)}_inform`}>
                      <InformationCreation
                        feature1Option={var1Option}
                        feature2Option={var2Option}
                        index={index}
                        control={control}
                        units={units}
                        subFileList={subFileList}
                        setSubFileList={setSubFileList}
                      />
                    </Col>
                    <Col span={24} style={{ textAlign: 'center' }}>
                      {index === information.length - 1
                        ? <Button className="add__more" onClick={addInformation}>Add more</Button>
                        : <Divider />}
                    </Col>
                  </Row>
                </Col>
              ))}

            </Row>
          </Col>
        </Row>
        <Row gutter={[20, 0]} justify="end" className="submit__section">
          <Col>
            <Button className="submit__btn cancel__btn" onClick={() => router.goBack()}>Cancel</Button>
          </Col>
          <Col>
            <Button htmlType="submit" className="submit__btn save__btn">Save</Button>
          </Col>
        </Row>
      </form>
    </div>
  );
};

export default ShopFormPage;

/* eslint-disable no-useless-catch */
import { useAppDispatch, useAppSelector } from '../reducers/store';
import * as productService from '../services/product-service';
import * as minimunOrderService from '../services/minimum-order-service';
import * as productSlice from '../reducers/state/product-slice';
import { ICreateProduct, IUpdateProduct } from '../interface/Product';

const productHook = () => {
  const {
    products, categorys, units, isLoading, total, minimumOrder,
  } = useAppSelector((state) => state.product);
  const dispatch = useAppDispatch();

  const fetchProduct = async (keyword = '', page = 1, size = 10) => {
    try {
      dispatch(productSlice.setLoading(true));
      const { data } = await productService.getProduct(keyword, page, size);
      if (page <= 1) {
        dispatch(productSlice.setProduct(data));
      } else {
        dispatch(productSlice.addProduct(data));
      }
    } catch (err) {
      // throw err;
    } finally {
      dispatch(productSlice.setLoading(false));
    }
  };

  const findProduct = async (productId: string) => {
    try {
      dispatch(productSlice.setLoading(true));
      const { data } = await productService.findProduct(productId);
      return data;
    } catch (err) {
      return undefined;
    } finally {
      dispatch(productSlice.setLoading(false));
    }
  };

  const createProduct = async (formData: ICreateProduct) => {
    try {
      dispatch(productSlice.setLoading(true));
      const { data } = await productService.create(formData);
      console.log('create product => ', data);
    } catch (err) {
      // throw err;
    } finally {
      dispatch(productSlice.setLoading(false));
    }
  };

  const updateProduct = async (formData: IUpdateProduct) => {
    try {
      dispatch(productSlice.setLoading(true));
      const { data } = await productService.update(formData);
      console.log('update product => ', data);
    } catch (err) {
      // throw err;
    } finally {
      dispatch(productSlice.setLoading(false));
    }
  };

  const fetchCategory = async () => {
    try {
      dispatch(productSlice.setLoading(true));
      const { data } = await productService.getCategory();
      dispatch(productSlice.setCategory(data));
    } catch (err) {
      // throw err;
    } finally {
      dispatch(productSlice.setLoading(false));
    }
  };

  const fetchUnit = async () => {
    try {
      dispatch(productSlice.setLoading(true));
      const { data } = await productService.getUnit();
      dispatch(productSlice.setUnit(data));
    } catch (err) {
      // throw err;
    } finally {
      dispatch(productSlice.setLoading(false));
    }
  };

  const setLoading = (flag: boolean) => {
    dispatch(productSlice.setLoading(flag));
  };

  const getMinimumOrder = async () => {
    try {
      dispatch(productSlice.setLoading(true));
      const { data } = await minimunOrderService.get();
      dispatch(productSlice.setMinimumOrder(data));
    } catch (err) {
      // throw err;
    } finally {
      dispatch(productSlice.setLoading(false));
    }
  };

  const updateMinimumOrder = async (mini: number) => {
    try {
      dispatch(productSlice.setLoading(true));
      await minimunOrderService.update(mini);
      const { data } = await minimunOrderService.get();
      dispatch(productSlice.setMinimumOrder(data));
    } catch (err) {
      // throw err;
    } finally {
      dispatch(productSlice.setLoading(false));
    }
  };

  return {
    findProduct,
    fetchProduct,
    createProduct,
    updateProduct,
    fetchCategory,
    getMinimumOrder,
    updateMinimumOrder,
    fetchUnit,
    setLoading,
    products,
    total,
    categorys,
    units,
    isLoading,
    minimumOrder,
  };
};

export default productHook;

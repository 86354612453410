/* eslint-disable no-underscore-dangle */
/* eslint-disable max-len */
import { FC, useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';

import {
  Button, Col, Input, InputNumber, Radio, Row, Space, Steps,
} from 'antd';
import './index.scss';
import {
  Controller, useForm,
} from 'react-hook-form';
import ArrowBackIcon from '../../common/icons/arrow_back';
import TableOrderProduct from '../../components/TableOrderProduct';
import { IOrderConfirmOrderRequest, IOrderDetail, IOrderRejectRequest } from '../../interface/Order';
import ModalCancelOrder from '../../components/ModalCancelOrder';
import orderHook from '../../hooks/order-hook';
import { formatMoney } from '../../common/hepler';
import PaymentInfoHook from '../../hooks/payment-info-hook';
import ModalAddPayment from '../../components/ModalAddPayment';
import { IPaymentInfo } from '../../interface/Payment';
import { confirmOrder } from '../../services/order-service';

const { Step } = Steps;

type ConfirmOrderParams = {
  id: string
}
const ConfirmOrder: FC = () => {
  const { id }: ConfirmOrderParams = useParams();
  const router = useHistory();
  const [modalCancel, setModalCancel] = useState(false);
  const { fetchOrderDetail, rejectOder } = orderHook();
  const { addPaymentInfo, fetchPaymentInfo, paymentInfo } = PaymentInfoHook();

  const [orderByID, _orderByID] = useState<IOrderDetail>();
  const [shippingPrice, _shippingPrice] = useState<number>(0);
  const [addPaymentModal, _addPaymentModal] = useState<boolean>(false);

  const {
    handleSubmit, control,
  } = useForm<IOrderConfirmOrderRequest>();

  const cancelOrder = async (reason: string) => {
    try {
      const form: IOrderRejectRequest = { orderId: id, reason };
      setModalCancel(false);
      await rejectOder(form);
      router.push('/order');
    } catch (err) {
      console.log(err);
    }
  };

  const fetchData = async () => {
    const data = await fetchOrderDetail(id);
    if (data && data.orderStatus === '0') {
      _orderByID(data);
      await fetchPaymentInfo();
    } else {
      router.goBack();
    }
  };

  const addPaymentInfoModal = async (info: IPaymentInfo) => {
    await addPaymentInfo(info);
    await fetchPaymentInfo();
  };

  const submitForm = async (values: IOrderConfirmOrderRequest) => {
    if (orderByID) {
      try {
        const formData: IOrderConfirmOrderRequest = { ...values, orderId: orderByID?._id };
        await confirmOrder(formData);
        router.goBack();
      } catch (err) { console.log(err); }
    }
  };

  useEffect(() => {
    fetchData();
  }, [id]);

  let totalPrice = 0;
  if (shippingPrice !== undefined && orderByID?.totalPrice !== undefined) {
    totalPrice = shippingPrice + orderByID.totalPrice;
  }

  const stepBody = () => (
    <Row gutter={[12, 12]} className="body">
      <Col span={24} xs={24} md={12} className="info">
        <form onSubmit={handleSubmit(submitForm)}>
          <Row gutter={[0, 36]}>
            <Col span="24">
              <div className="box__info">
                <div className="box__info__head">
                  Shipping Info
                </div>
                <div className="box__info__body">
                  <div>
                    Please insert the shipping information
                  </div>
                  <div style={{ marginTop: '12px' }}>
                    <Row gutter={[12, 12]}>
                      <Col span={10} style={{ paddingTop: '4px' }}>
                        Shipping name:
                      </Col>
                      <Col span={14}>
                        <Controller
                          control={control}
                          name="shippingName"
                          render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <>
                              <Input className="input__order__confirm" onChange={onChange} value={value} />
                              {error ? <div className="error__input__text">{error.message}</div> : null}
                            </>
                          )}
                          rules={{ required: 'This field is required.' }}
                        />
                      </Col>
                      <Col span={10} style={{ paddingTop: '4px' }}>
                        Telephone number:
                      </Col>
                      <Col span={14}>
                        <Controller
                          control={control}
                          name="shippingPhoneNumber"
                          render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <>
                              <Input className="input__order__confirm" onChange={onChange} value={value} />
                              {
                                error
                                  ? (
                                    <div className="error__input__text">
                                      {error?.type === 'required' && 'This field is required.'}
                                      {error?.type === 'pattern' && 'Please enter only phone-number.'}
                                      {error?.type === 'minLength' && 'This field is least 9 char.'}
                                      {error?.type === 'maxLength' && 'This field is max 10 char.'}
                                    </div>
                                  )
                                  : null
                              }
                            </>
                          )}
                          rules={{
                            required: true, pattern: /\d/, maxLength: 10, minLength: 9,
                          }}
                        />
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </Col>
            <Col span="24">
              <div className="box__info">
                <div className="box__info__head">
                  Payment Info
                </div>
                <div className="box__info__body">
                  <div>
                    Please select the payment information
                  </div>
                  <Controller
                    control={control}
                    name="paymentInfo"
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                      <>
                        <Radio.Group size="small" className="radio__order__confirm" onChange={onChange} value={value}>
                          <Space direction="vertical">
                            {paymentInfo.length > 0 ? paymentInfo.map((pm, index) => (
                              <Radio value={pm._id} key={`${String(index)}__radio`}>
                                <div className="detail">
                                  {pm.brandName}
                                  <br />
                                  Bank account  number :
                                  {' '}
                                  {pm.accountNumber}
                                  <br />
                                  Branch :
                                  {' '}
                                  {pm.branch}
                                  <br />
                                  Account name :
                                  {' '}
                                  {pm.accountName}
                                </div>
                              </Radio>
                            )) : <div style={{ fontSize: '12px', color: '#535252' }}>Please add new payment information...</div>}
                          </Space>
                        </Radio.Group>
                        {error ? <div className="error__input__text">{error.message}</div> : null}
                      </>
                    )}
                    rules={{ required: 'This field is required.' }}
                  />

                  <Button className="add__payment" onClick={() => _addPaymentModal(true)}>
                    + Add new payment information
                  </Button>
                </div>
              </div>
            </Col>
            <Col span="24">
              <div className="box__info">
                <div className="box__info__head">
                  Price Summary
                </div>
                <div className="box__info__body">
                  <div>
                    Please insert the shipping price
                  </div>
                  <div style={{ marginTop: '12px' }}>
                    <Controller
                      control={control}
                      name="shippingFee"
                      render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <>
                          <InputNumber
                            className="input__order__confirm"
                            placeholder="$"
                            min={1}
                            onChange={(e) => {
                              onChange(e);
                              _shippingPrice(e);
                            }}
                            value={value}
                          />
                          {error ? <div className="error__input__text">{error.message}</div> : null}
                        </>
                      )}
                      rules={{ required: 'This field is required.', min: 1 }}
                    />

                  </div>
                  <div>
                    <Row justify="space-between" style={{ marginTop: 16 }}>
                      <Col>Product Price</Col>
                      <Col style={{ color: 'black' }}>
                        $
                        {formatMoney(orderByID?.totalPrice)}
                      </Col>
                    </Row>
                    <Row justify="space-between" style={{ marginTop: 12 }}>
                      <Col>Shipping Price</Col>
                      <Col style={{ color: 'black' }}>
                        $
                        {formatMoney(shippingPrice)}
                      </Col>
                    </Row>
                    <Row justify="space-between" style={{ marginTop: 24 }}>
                      <Col>Total</Col>
                      <Col style={{ color: 'black' }}>
                        $
                        {formatMoney(totalPrice)}
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </Col>
            <Col span="24" className="btn_control">
              <Row gutter={[14, 0]}>
                <Col span="12">
                  <Button className="cancel_btn" onClick={() => setModalCancel(true)}>Cancel</Button>
                </Col>
                <Col span="12">
                  <Button htmlType="submit" className="confirm_btn">Confirm</Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </form>
      </Col>
      <Col span={24} xs={24} md={12} className="details">
        <div className="address">
          <div>
            Shiping Order
          </div>
          <div>
            <div style={{ marginBottom: 8 }}>
              <div>
                <b>{orderByID?.retailerAddress.fullName}</b>
              </div>
              <div>
                <b>{orderByID?.retailerAddress.phoneNumber}</b>
              </div>
            </div>
            <div>
              {orderByID?.retailerAddress.address}
              {' '}
              {orderByID?.retailerAddress.province.name}
              {' '}
              {orderByID?.retailerAddress.country.name}
              {' '}
            </div>
            {orderByID?.retailerAddress.postalCode}
          </div>
        </div>
        <div className="order_detail">
          <div>
            Order details
          </div>
          <TableOrderProduct rows={orderByID?.orders || []} />
        </div>
      </Col>
    </Row>
  );

  return (
    <div className="confirm_layout confirm__order__page">
      <div>
        <Button onClick={() => router.goBack()} ghost>
          <ArrowBackIcon />
        </Button>
        <Steps size="small" current={0} labelPlacement="vertical">
          <Step subTitle="Confirm order" />
          <Step subTitle="Wait for payment" />
          <Step subTitle="Confirm payment" />
        </Steps>
        <div />
      </div>

      {stepBody()}

      <ModalCancelOrder
        visible={modalCancel}
        handleOk={cancelOrder}
        handleCancel={() => setModalCancel(false)}
      />

      <ModalAddPayment
        visible={addPaymentModal}
        handleOk={addPaymentInfoModal}
        handleCancel={() => _addPaymentModal(false)}
      />

    </div>
  );
};

export default ConfirmOrder;

/* eslint-disable no-underscore-dangle */
/* eslint-disable react/destructuring-assignment */
import { Button } from 'antd';
import { useHistory } from 'react-router';
import { IColumnTableProductProp, IProduct } from '../../interface/Product';
import './index.scss';

const ColumnTableOrder = ():IColumnTableProductProp[] => [
  {
    title: 'Product Name',
    index: 'product',
    width: '300px',
    render: (props: IProduct) => (
      <div className="column_product">
        <div className="image_product" style={{ backgroundImage: `url(${props.images[0]?.url || ''})` }} />
        <div className="detail">
          <div>
            {props.product}
          </div>
          <div>
            {props.description}
          </div>
        </div>
      </div>
    ),
  },
  // {
  //   title: 'SKU No.',
  //   index: 'sku',
  //   align: 'center',
  //   width: '150px',
  // },
  // {
  //   title: 'ตัวเลือกสินค้า',
  //   index: 'attribute',
  //   align: 'center',
  //   width: '150px',
  // },
  {
    title: 'ราคา',
    index: '_id',
    align: 'center',
    render: (props:IProduct) => <div>{`${props.minPrice} - ${props.maxPrice}`}</div>,
  },
  {
    title: 'คลัง',
    index: 'stock',
    align: 'center',
  },
  {
    title: 'ยอดขาย',
    index: 'order',
    align: 'center',
  },
  {
    title: 'การดำเนินการ',
    index: '_id',
    align: 'center',
    width: '150px',
    render: (props:IProduct) => {
      const router = useHistory();
      return (
        <Button ghost type="text" style={{ fontSize: 16, fontWeight: 400 }} onClick={() => router.push(`/shop/form/${props._id}`)}>
          แก้ไข
        </Button>
      );
    },
  },
];

export default ColumnTableOrder;

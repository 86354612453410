/* eslint-disable max-len */
import { AxiosResponse } from 'axios';
import {
  IOrder, IOrderConfirmOrderRequest, IOrderDetail, IOrderRejectRequest, IOrderResponse,
} from '../interface/Order';
import $axios from '../plugin/axios';

export const getAll = async (
  keyword:string,
  page: number,
  size: number,
):Promise<AxiosResponse<IOrderResponse>> => {
  const response = await $axios.get<IOrderResponse>(`/order/wholesaler-order?keyword=${keyword}&page=${page}&size=${size}`);
  return response;
};

export const getAllStatus = async (
  orderStatus:string,
  keyword:string,
  page: number,
  size: number,
):Promise<AxiosResponse<IOrderResponse>> => {
  const response = await $axios.get<IOrderResponse>(`/order/wholesaler-order/${orderStatus}?keyword=${keyword}&page=${page}&size=${size}`);
  return response;
};

export const getOne = async (orderId:string):Promise<AxiosResponse<IOrderDetail>> => {
  const response = await $axios.get<IOrderDetail>(`/order/wholesaler-detail/${orderId}`);
  return response;
};

export const confirmOrder = async (form:IOrderConfirmOrderRequest):Promise<AxiosResponse<IOrder>> => {
  const response = await $axios.post('/order/wholesaler-confirm-order', form);
  return response;
};

export const confirmPayment = async (orderId:string):Promise<AxiosResponse> => {
  const response = await $axios.post('/order/wholesaler-confirm-payment', { orderId });
  return response;
};

export const rejectOrder = async (form:IOrderRejectRequest):Promise<AxiosResponse> => {
  const response = $axios.post('/order/wholesaler-reject-order', form);
  return response;
};

export const shippingOrder = async (orderId:string):Promise<AxiosResponse> => {
  const response = await $axios.post('/order/wholesaler-shipping-order', { orderId });
  return response;
};

export const getAllShipping = async (
  keyword:string,
  page: number,
  size: number,
):Promise<AxiosResponse<IOrderResponse>> => {
  const response = await $axios.get<IOrderResponse>(`/order/wholesaler-shipping?keyword=${keyword}&page=${page}&size=${size}`);
  return response;
};

export const getAllShippingStatus = async (
  orderStatus:string,
  keyword:string,
  page: number,
  size: number,
):Promise<AxiosResponse<IOrderResponse>> => {
  const response = await $axios.get<IOrderResponse>(`/order/wholesaler-shipping/${orderStatus}?keyword=${keyword}&page=${page}&size=${size}`);
  return response;
};

import {
  FC, useCallback, useEffect, useState,
} from 'react';
import { Button, Input, Tabs } from 'antd';
import './index.scss';
import { SearchOutlined } from '@ant-design/icons';
import { debounce } from 'lodash';
import TableOrder from '../../components/TableOrder';
import orderHook from '../../hooks/order-hook';

const { TabPane } = Tabs;

const Order:FC = () => {
  const [queryStatus, _queryStatus] = useState<string>('all');
  const [queryString, _queryString] = useState<string>('');
  const [page, _page] = useState<number>(1);
  const {
    fetchOrder, fetchOrderByStatus, orders, total,
  } = orderHook();

  const fetchData = async () => {
    if (queryStatus === 'all') {
      await fetchOrder(queryString, page, 10);
    } else {
      await fetchOrderByStatus(queryStatus, queryString, page, 10);
    }
  };

  const debouncedSearch = useCallback(
    debounce(async (query:string) => {
      _queryString(query);
    }, 1000),
    [],
  );

  const callback = (key:string) => {
    _queryStatus(key);
  };

  useEffect(() => {
    fetchData();
  }, [page, queryString, queryStatus]);

  useEffect(() => {
    _page(1);
  }, [queryString, queryStatus]);

  const loadmore = () => {
    const nextPage = page + 1;
    _page(nextPage);
  };

  return (
    <div className="order__page">
      <div>
        <h1>Orders</h1>
        <div className="input__section">
          <Input placeholder="Search orders" prefix={<SearchOutlined />} onChange={(e: { target: { value: string; }; }) => debouncedSearch(e.target.value)} />
        </div>
        <Tabs defaultActiveKey="all" onChange={callback} tabBarGutter={0}>
          <TabPane tab="All" key="all">
            <TableOrder rows={orders} />
          </TabPane>
          <TabPane tab="Wait for confirm order" key="0">
            <TableOrder rows={orders} />
          </TabPane>
          <TabPane tab="Wait for payment" key="1">
            <TableOrder rows={orders} />
          </TabPane>
          <TabPane tab="Wait for confirm payment" key="2">
            <TableOrder rows={orders} />
          </TabPane>
          <TabPane tab="Reject" key="10">
            <TableOrder rows={orders} />
          </TabPane>
        </Tabs>
        {total > orders.length ? (
          <div className="text-center">
            <Button onClick={loadmore} className="load__more__btn">Load more...</Button>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default Order;

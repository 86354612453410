import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  ICategory, IMinimumOrder, IProduct, IProductResponse, IUnit,
} from '../../interface/Product';
// Define a type for the slice state
interface ProductState {
  products: IProduct[];
  total: number;
  categorys: ICategory[]
  units:IUnit[]
  isLoading: boolean
  minimumOrder: number
}

// Define the initial state using that type
const initialState: ProductState = {
  products: [],
  total: 0,
  categorys: [],
  units: [],
  isLoading: false,
  minimumOrder: 0,
};

export const productSlice = createSlice({
  name: 'product',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setProduct(state, action: PayloadAction<IProductResponse>) {
      return {
        ...state,
        products: action.payload.docs,
        total: action.payload.total,
      };
    },
    addProduct(state, action: PayloadAction<IProductResponse>) {
      const addProduct = [...state.products, ...action.payload.docs];
      return {
        ...state,
        products: addProduct,
        total: action.payload.total,
      };
    },
    setMinimumOrder(state, action:PayloadAction<IMinimumOrder>) {
      return {
        ...state,
        minimumOrder: action.payload.minimumOrder,
      };
    },
    setCategory(state, action:PayloadAction<ICategory[]>) {
      return {
        ...state,
        categorys: action.payload,
      };
    },
    setUnit(state, action:PayloadAction<IUnit[]>) {
      return {
        ...state,
        units: action.payload,
      };
    },
    setLoading(state, action: PayloadAction<boolean>) {
      return {
        ...state,
        isLoading: action.payload,
      };
    },
  },
});

export const {
  setProduct, addProduct, setCategory, setUnit, setLoading, setMinimumOrder,
} = productSlice.actions;

export default productSlice.reducer;

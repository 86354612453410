import { IUserLogin } from '../interface/User';
import * as authSlice from '../reducers/state/auth-slice';
import * as authService from '../services/auth-service';
import { useAppDispatch, useAppSelector } from '../reducers/store';

const AuthHook = () => {
  const dispatch = useAppDispatch();
  const {
    isLoading, me, accessToken, isModalLoginVisible, isModalRegisterVisible, registerEmail,
  } = useAppSelector((state) => state.auth);

  const setVisibleModalLogin = (flag:boolean) => {
    dispatch(authSlice.setVisibleModalLogin(flag));
  };

  const setVisibleModalRegister = (flag:boolean) => {
    dispatch(authSlice.setVisibleModalRegister(flag));
  };

  const login = async (form:IUserLogin) => {
    dispatch(authSlice.setLoading(true));
    const { data } = await authService.loginService(form);
    dispatch(authSlice.setToken(data));
    localStorage.setItem('accessToken', data.accessToken);
    localStorage.setItem('refreshToken', data.refreshToken);
    dispatch(authSlice.setLoading(false));
  };

  const logout = async () => {
    dispatch(authSlice.setLoading(true));
    await authService.logoutService();
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    dispatch(authSlice.setLoading(false));
  };

  const setRegisterEmail = (email:string) => {
    dispatch(authSlice.setRegisterEmail(email));
  };

  const clearToken = () => {
    dispatch(authSlice.setToken({ accessToken: undefined, refreshToken: undefined }));
  };

  return {
    clearToken,
    isLoading,
    me,
    accessToken,
    isModalLoginVisible,
    isModalRegisterVisible,
    registerEmail,
    setVisibleModalLogin,
    setVisibleModalRegister,
    setRegisterEmail,
    login,
    logout,
  };
};

export default AuthHook;
